@import url("https://fonts.googleapis.com/css?family=Open+Sans");

/* stylelint-disable no-descending-specificity, at-rule-no-unknown */

/**
 *  Property of OSF Global Services, Inc., (with its brand OSF Commerce). OSF remains the sole owner of all right, title and interest in the software.
 *  Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

/* the values used in this file assume a root font size equivaled to 10 pixels
* the scalesize function scales the values by the actual root font size
* for Sitegenesis and SFRA root font size is equivale to 16 pixels
*/

@function scalesize($n) {
    @return $n / 1.6;
}

$media-450: scalesize(45rem);
$media-500: scalesize(50rem);
$media-750: scalesize(75rem);
$media-1024: scalesize(102.4rem);

$body-font: "proxima-nova", sans-serif;
$head-font: "futura-pt-condensed", sans-serif;

.full-width {
    .primary-content {
        border: 0 none;
        width: 100%;
    }
}

ul.options_link {
    list-style-type: none;
    padding-left: 0;

    li {
        display: inline;
    }
}

/*
.primary-content {
    float: right;
    margin-left: scalesize(-0.1rem);
    padding: 0 0 scalesize(2rem);
    position: relative;
    width: 75%;
}
*/

.primary-focus {
    .primary-content {
        border: 0 none;
        float: left;
        margin: 0;
        width: 75%;
    }
}

.page-content {
    .primary-content {
        padding-left: scalesize(2.9rem);
    }
}

#login {
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    .ui-dialog-content {
        padding: 0;
        width: 90% !important;
        margin: 0 auto;
    }
}

table.orders {
    border: scalesize(0.2rem) solid white;
    border-collapse: separate;
    border-spacing: scalesize(0.2rem);
    margin-left: scalesize(-0.2rem);
    width: 97%;

    th {
        background-color: #ccc;
        padding: 1em;
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: #f0f0f0;
            }
        }
    }
}

table#subscriptions {
    border: scalesize(0.2rem) solid white;
    border-collapse: separate;
    border-spacing: scalesize(0.2rem);
    margin-left: scalesize(-0.2rem);
    width: 97%;

    th {
        background-color: #ccc;
        padding: 1em;
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: #f0f0f0;
            }
        }
    }
}

.ui-dialog {
    top:0;
    left:0;
}
div#QuickViewDialog.ui-dialog-content {

}


.ui-dialog {
    .ui-dialog-content {
        padding: 0;
        width: 90% !important;
        min-height: auto !important;
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {
    .ui-dialog {
        top: auto;
        left: auto;
    }
    .ui-dialog .ui-dialog-buttonpane button {
        margin-left: 15px !important;
    }
}
@media screen and (max-width: 767px) {
    .bonus-product-dialog {
        top: 0px !important;
    }
    .ui-dialog .ui-dialog-buttonpane button {
        margin-left: 15px !important;
    }
}

#orderview {
    z-index: 99;
    padding: 2em 0 4em;
    font-size: scalesize(1.4rem);
    font-family: $body-font;

    .header {
        border-bottom: scalesize(0.1rem) solid black;
    }

    .order-section {
        border-bottom: scalesize(0.1rem) solid black;
        padding: 1em 0;

        p.title {
            margin-left: 0;
            width: 25%;
            width: 100%;
        }

        .submit {
            position: relative;
            bottom: 2.85em;
            left: 90%;
        }

        table.product {
            margin-top: 1em;
        }

        p.title-little {
            width: 25%;
        }

        .product {

            &:not(:first-child) {
                border-top: scalesize(0.1rem) solid black;
            }

            thead {
                tr {
                    th {
                        border-bottom: scalesize(0.1rem) solid #c5c5c5;
                        padding: scalesize(1rem) 0;
                    }
                }
            }

            .no-padding-left {
                padding-left: 0;
            }

            .quantity {
                width: 25%;
            }
        }
    }

    .payment-section {
        border-bottom: scalesize(0.1rem) solid black;

        p {
            font-weight: 900;
        }

        p.tax {
            font-weight: normal;
            display: block;
        }
    }

    .last {
        border: none;
    }

    .title {
        font-weight: 900;
    }

    .content-section {
        display: inline-block;
        width: 100%;

        table {
            margin-left: -1em;
            margin-bottom: 0.5em;
            width: 100%;
            table-layout: fixed;

            tr {
                td {
                    &:first-child {
                        font-weight: 900;
                        padding: 0.4em 0;
                        padding-left: 1em;
                    }
                }
            }

            td {
                max-width: 10%;
                vertical-align: middle;
                white-space: nowrap;

                span.name {
                    font-weight: normal;
                }
            }

            td.phone-number {
                width: 7em;
            }
        }
    }

    .product {
        td {
            vertical-align: top;
            padding: 1em;
            width: 25%;
        }

        input {
            display: block;
            width: 100%;
            padding: scalesize(0.5rem);
        }

        select {
            display: block;
            width: 100%;
            padding: scalesize(0.5rem);
        }
    }

    #actions {
        display: inline-block;
        position: relative;
        top: 0.75em;
        width: 100%;

        span {
            margin: 0.2em 0;
        }
    }
}

#subscriptionview {
    z-index: 99;
    padding: 2em 0 4em;
    font-size: scalesize(1.4rem);
    font-family: $body-font;
    overflow: scroll;
    height: 400px !important;

    .header {
        border-bottom: scalesize(0.1rem) solid black;
    }

    .order-section {
        border-bottom: scalesize(0.1rem) solid black;
        padding: 1em 0;

        p.title {
            margin-left: 0;
            width: 25%;
            width: 100%;
        }

        .submit {
            position: relative;
            bottom: 2.85em;
            left: 90%;
        }

        table.product {
            margin-top: 1em;
        }

        p.title-little {
            width: 25%;
        }

        .product {

            &:not(:first-child) {
                border-top: scalesize(0.1rem) solid black;
            }

            thead {
                tr {
                    th {
                        border-bottom: scalesize(0.1rem) solid #c5c5c5;
                        padding: scalesize(1rem) 0;
                    }
                }
            }

            .no-padding-left {
                padding-left: 0;
            }

            .quantity {
                width: 25%;
            }
        }
    }

    .payment-section {
        border-bottom: scalesize(0.1rem) solid black;

        p {
            font-weight: 900;
        }

        p.tax {
            font-weight: normal;
            display: block;
        }
    }

    .last {
        border: none;
    }

    .content-section {
        display: inline-block;
        width: 100%;

        table {
            margin-bottom: 0.5em;
            width: 100%;
            table-layout: fixed;

            tr {
                td {
                    &:first-child {
                        font-weight: 900;
                        padding: 0.4em 0;
                        padding-left: 1em;
                    }
                }
            }

            td {
                max-width: 10%;
                vertical-align: middle;
                white-space: nowrap;

                span.name {
                    font-weight: normal;
                }
            }

            td.phone-number {
                width: 7em;
            }
        }
    }

    .product {
        td {
            vertical-align: top;
            padding: 1em;
            width: 25%;
        }

        input {
            display: block;
            width: 100%;
            padding: scalesize(0.5rem);
        }

        select {
            display: block;
            width: 100%;
            padding: scalesize(0.5rem);
        }
    }

    #actions {
        display: inline-block;
        position: relative;
        top: 0.75em;
        width: 100%;

        span {
            margin: 0.2em 0;
            float: left;
        }
    }
}

#addresschange {
    #editAddressForm {
        margin: 0;

        #dialogApplyBtn {
            position: absolute;
            right: 24%;
            display: none;
        }

        .select {
            label {
                padding: 0;
            }
        }
    }
}

#cancel-all-dialog {
    h3 {
        width: 95%;
        margin: 2em auto 0;
    }
}

.select-group {
    float: left;
}

.group-change-refill {
    display: inline-block;

    .title-little {
        width: auto !important;
        white-space: nowrap;
    }

    .select-group {
        float: none !important;
    }
}

.update-change-refill {
    display: inline-block;
    margin-right: scalesize(1rem);

    .title-little {
        width: auto !important;
        white-space: nowrap;
    }

    .select-group {
        float: none !important;
    }
}

.payment-section__card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: scalesize(1rem) 0;
    border-top: 0;
    border-bottom: scalesize(0.1rem) solid #c5c5c5;
}

.payment-section__order {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: scalesize(1rem) 0;
    border-top: 0;
    border-bottom: scalesize(0.1rem) solid #c5c5c5;
}

.payment-section__card--item {
    display: inline-block;
    width: 50%;

    .title-order {
        font-weight: 900;
    }

    > * {
        display: inline;
    }
}

.payment-section__order--item {
    display: inline-block;
    width: 50%;

    .title-order {
        font-weight: 900;
    }

    > * {
        display: inline;
    }
}

.payment-section__card--item-full {
    display: inline-block;
    width: 100%;
    text-align: center;

    > * {
        display: inline;
        margin: 0;
    }
}

.payment-section__order--item-full {
    display: inline-block;
    width: 100%;
    text-align: center;

    > * {
        display: inline;
        margin: 0;
    }
}

.payment-section__card--item-last {
    text-align: right;
}

.payment-section__order--item-last {
    text-align: right;
}

.update-card {
    display: block;
    margin: 1em 0;
}

#my_subscriptions {
    font-family: $body-font;

    .subscriptionSection {
        margin-bottom: scalesize(5rem);

        .product-out-of-stock {
            font-weight: bold;
            color: red;
            display: block;
        }
    }

    .subscriptionHeader {
        font-size: 32px;
        line-height: 40px;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .subscriptionSection-img {
        img {
            width: scalesize(20rem);
            height: auto;
            margin: 24px 24px 24px 0;
            @media screen and (max-width: $media-750) {
                margin: 24px 0;
            }
        }

        .quantity-name {
            font-weight: 400;
            font-size: scalesize(2rem);
        }

        .description-label {
            margin-left: scalesize(1rem);
        }
    }

    .description-label {
        font-size: 1.3em;
        font-weight: 400;
    }

    .description-value {
        font-size: 1.3em;
        font-weight: 400;
        display: inline-block;

        > span {
            display: inline-block;
            font-weight: 700;
            line-height: 1em;
            padding-right: scalesize(1rem);
            border-right: scalesize(0.1rem) solid #333;

            &:last-child {
                border: none;
            }
        }
    }

    .show-hide-orders {
        cursor: pointer;
        font-size: 1.5em;
        border-bottom: scalesize(0.1rem) solid #9e9e9e;
        padding-bottom: scalesize(1rem);
        width: calc(100% - scalesize(10rem));
        margin-right: scalesize(10rem);

        &:hover {
            text-shadow: scalesize(0.4rem) scalesize(0.4rem) scalesize(0.6rem) #cccaca;
        }
    }

    .subscriptionOrders {
        display: none;
    }

    .sorlink.pausesubscription {
        background-color: #2698e8;
        color: #fff;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        width: scalesize(9.5rem);
        height: scalesize(4rem);
        margin: scalesize(1rem);
        font-size: 1.3em;

        img {
            width: scalesize(1.2rem);
            height: auto;
            margin-right: scalesize(0.3rem);
        }
    }

    .sorlink.reactivatesubscription {
        background-color: #2698e8;
        color: #fff;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        height: scalesize(4rem);
        margin: scalesize(1rem);
        font-size: 1.3em;
        width: scalesize(12rem);

        img {
            width: scalesize(1.2rem);
            height: auto;
            margin-right: scalesize(0.3rem);
        }
    }

    .sorlink.reactivaterenewal {
        background-color: #2698e8;
        color: #fff;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        height: scalesize(4rem);
        margin: scalesize(1rem);
        font-size: 1.3em;
        width: scalesize(18rem);

        img {
            width: scalesize(1.2rem);
            height: auto;
            margin-right: scalesize(0.3rem);
        }
    }

    .sorlink.cancelsubscription {
        background-color: #e27252;
        color: #fff;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        width: scalesize(9.5rem);
        height: scalesize(4rem);
        margin: scalesize(1rem);
        font-size: 1.3em;

        img {
            width: scalesize(1.2rem);
            height: auto;
            margin-right: scalesize(0.3rem);
        }
    }
}

.subscriptionOrders {
    .orders {
        list-style-type: none;
        padding: scalesize(0.5rem);
        margin: scalesize(1rem) scalesize(10rem) scalesize(1rem) 0;

        li {
            border-bottom: scalesize(0.1rem) solid #e0dede;
            -ms-flex-align: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-pack: justify;
            justify-content: space-between;

            &:last-child {
                border: none;
            }
        }
    }

    .orders-date {
        font-weight: 700;
        font-size: 1.3em;
    }

    .orders-buttons {
        font-weight: 700;
        display: -ms-flexbox;
        display: flex;

        .order.skip.sorlink {
            all: unset;
            color: #e27252;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-align: center;
            align-items: center;
            width: scalesize(7rem);
            height: scalesize(2rem);
            font-size: 1.3em;
            margin: scalesize(1rem) 0;
            cursor: pointer;

            img {
                width: scalesize(1.2rem);
                height: auto;
                margin-right: scalesize(0.3rem);
            }
        }

        #subscriptionOrders__view-button.order.view.sorlink {
            color: #b52532;
            background-color: transparent;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-align: center;
            align-items: center;
            width: scalesize(5rem);
            height: scalesize(2rem);
            font-size: 1.3em;

            img {
                width: scalesize(1.3rem);
                height: auto;
                margin-right: scalesize(0.3rem);
            }
        }

        .order.pause.sorlink {
            color: #2698e8;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-align: center;
            align-items: center;
            width: scalesize(9rem);
            height: scalesize(2.5rem);
            font-size: 1.3em;
            margin: scalesize(1rem) 0;

            img {
                width: scalesize(1.2rem);
                height: auto;
                margin-right: scalesize(0.3rem);
            }
        }

        .order.reactivate.sorlink {
            color: #2698e8;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-align: center;
            align-items: center;
            font-size: 1.3em;
            margin: scalesize(1rem) 0;
            width: scalesize(9rem);
            height: scalesize(2rem);

            img {
                width: scalesize(1.2rem);
                height: auto;
                margin-right: scalesize(0.3rem);
            }
        }
    }
}

span.sorlink {
    cursor: pointer;
    color: #b52532;
    outline: medium none;
    overflow: hidden;
    text-decoration: none;
    font-size: scalesize(1.4rem);
}

.hide {
    display: none;
}

#pdpMain {
    font-family: $body-font;
}

.sor-options-description {
    all: initial;
    font-family: $body-font;
    font-size: scalesize(1.6rem);
    margin-top: 0;
    color: #333;
    display: block;
}

.smart-order-refill-options {
    .or-cancel-edit-text {
        all: initial;
        position: relative;
        max-width: 100%;
        font-size: scalesize(1.5rem);
        margin: scalesize(1rem) scalesize(1rem) scalesize(1.5rem) scalesize(1rem);
        font-family: $body-font;
        color: #333;
        display: block;
    }

    .full_row {
        position: relative;
        padding: 0 scalesize(0.6rem);
        margin: scalesize(1.5rem) 0;

        input[type="radio"] {
            display: none;
        }

        label {
            all: initial;
            font-family: $body-font;
            color: #666;
            font-size: scalesize(1.6rem);
            font-weight: normal;
            display: inline-block;
            margin: 0;
            max-width: 85%;
            position: relative;
            vertical-align: middle;

            &::before {
                all: initial;
                content: " ";
                display: inline-block;
                position: relative;
                top: scalesize(0.5rem);
                margin: 0 scalesize(0.5rem) 0 0;
                width: scalesize(1.6rem);
                height: scalesize(1.6rem);
                border-radius: scalesize(1.1rem);
                border: scalesize(0.2rem) solid #84bd00;
                background-color: transparent;
            }
        }

        input[type=radio] {
            &:checked {
                + {
                    label {
                        &::after {
                            border-radius: scalesize(1.1rem);
                            width: scalesize(1.2rem);
                            height: scalesize(1.2rem);
                            position: absolute;
                            bottom: scalesize(0.4rem);
                            left: scalesize(0.4rem);
                            content: " ";
                            display: block;
                            background: #84bd00;
                        }
                    }
                }
            }
        }
    }

    .smart-order-refill-period {
        display: inline-block;
        vertical-align: middle;
    }

    .sor-price {
        all: initial;
        font-family: $body-font;
        font-size: scalesize(2rem);
        margin: 0;
        max-width: 85%;
        position: relative;
        display: block;
        font-weight: bold;
        margin-left: scalesize(1.5rem);
    }
}

.sor-single-option {
    font-size: scalesize(1.6rem);
    font-weight: 600;
    margin: 0;
}

.sor-single-option__message {
    font-weight: 400;
}

#everyDelivery {
    display: inline-block;
    margin-left: scalesize(0.7rem);
    width: inherit;
}

#SorDeliveryMonthInterval {
    display: inline-block;
    margin-left: scalesize(0.7rem);
    width: inherit;
}

#SorDeliveryWeekInterval {
    display: inline-block;
    margin-left: scalesize(0.7rem);
    width: inherit;
}

.smartOrderRefillOnly {
    h3 {
        all: initial;
        font-family: $body-font;
        font-size: scalesize(2rem);
        margin: 0;
        max-width: 85%;
        position: relative;
        display: block;
        font-weight: bold;
        margin-left: scalesize(0.6rem);
        margin-bottom: scalesize(1rem);
    }
}

.refill-info-links {
    font-size: scalesize(1.2rem);
    line-height: 1em;
}

#modify-smart-order-refill {
    .option {
        position: relative;
        padding: 0 scalesize(0.6rem);
        margin: scalesize(1.5rem) 0;

        input[type="radio"] {
            display: none;
        }

        label {
            all: initial;
            font-family: $body-font;
            color: #666;
            font-size: scalesize(1.4rem);
            font-weight: normal;
            display: inline-block;
            margin: 0;
            position: relative;
            vertical-align: middle;

            &::before {
                all: initial;
                content: " ";
                display: inline-block;
                position: relative;
                top: scalesize(0.5rem);
                margin: 0 scalesize(0.5rem) 0 0;
                width: scalesize(1.4rem);
                height: scalesize(1.4rem);
                border-radius: scalesize(1.1rem);
                border: scalesize(0.2rem) solid #84bd00;
                background-color: transparent;
            }
        }

        label.hide {
            display: none;
        }

        input[type=radio] {
            &:checked {
                + {
                    label {
                        &::after {
                            border-radius: scalesize(1.1rem);
                            width: scalesize(1rem);
                            height: scalesize(1rem);
                            position: absolute;
                            bottom: scalesize(0.3rem);
                            left: scalesize(0.4rem);
                            content: " ";
                            display: block;
                            background: #84bd00;
                        }
                    }
                }
            }
        }
    }

    .refill-period {
        display: inline-block;
        vertical-align: middle;
    }

    select {
        border: scalesize(0.1rem) solid darkgray;
        position: relative;
        margin-left: scalesize(0.5rem);
        height: scalesize(1.8rem);
        font-size: scalesize(1.1rem);
        font-family: $body-font;
        width: inherit;
    }
}

#modifyRefill {
    + {
        #removeRefill {
            &::before {
                content: "|";
                padding: 0 scalesize(0.4rem) 0 scalesize(0.2rem);
                color: initial;
            }
        }
    }
}

#message_wrapper {
    all: unset;
    font-family: $body-font;
    font-size: scalesize(1.2rem);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: scalesize(3.6rem);
    background-color: #e27252;
    cursor: default;
    position: relative;
    bottom: scalesize(-7rem);

    a {
        all: unset;
        color: #fff;
        font-size: 1em;
        cursor: pointer;

        strong {
            all: unset;
            color: #fff;
            font-size: 1em;
            font-weight: bold;
        }
    }

    p {
        all: unset;
        color: #fff;
        font-size: 1em;
    }
}

.sorlogin-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: scalesize(2.5rem) 0;
}

.sorlogin-col {
    -ms-flex: 1;
    flex: 1;
    max-width: 48%;

    h2 {
        all: unset;
        font-family: $body-font;
        text-transform: uppercase;
        font-style: italic;
        font-size: scalesize(2.1rem);
        margin: scalesize(0.5rem) auto;
    }
}

#sorlogin {
    .login-rememberme {
        display: inline-block;
        margin: scalesize(0.6rem);

        label {
            display: inline-block;
            vertical-align: middle;
        }

        .field-wrapper {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

#loginFromCartError {
    background-color: #cebdb3;
    border: scalesize(0.1rem) solid #900;
    border-radius: 0.5em;
    color: #900;
    margin: scalesize(0.1rem) 0;
    padding: scalesize(1rem);
    font-family: $body-font;
    font-size: scalesize(1.2rem);
}

.sor-form-row.label-inline {
    label {
        display: inline;
        float: none;
        margin-top: 0;
        text-align: left;
        width: auto;
    }

    input[type="checkbox"] {
        margin: 0 scalesize(0.5rem) 0 0;
    }

    input[type="radio"] {
        margin: 0 scalesize(0.5rem) 0 0;
    }

    &::after {
        content: " ";
        clear: both;
    }

    .field-wrapper {
        float: left;
        padding-left: 0;
        width: auto;
    }
}

.mini-cart-link-checkout.hide-checkout-link {
    visibility: hidden;
    margin: 0;
}

.credit_card_save_text {
    margin: scalesize(1rem) auto;
    width: 55%;

    .credit_card_expiration_warning {
        font-weight: bold;
        color: red;
        margin: 0;
    }
}

.tab-content {
    .credit_card_save_text {
        width: auto;
    }
}

.payment-method {
    .save_card {
        display: none;
    }
}

.refill-dashboard {
    font-size: scalesize(1.2rem);

    .header {
        font-size: scalesize(1.6rem);
    }
}

.form-row {
    &.sor-form-row {
        &.month {
            margin-right: scalesize(1rem);
            @media screen and (max-width: $media-1024) {
                width: 25%;
            }
        }
        &.year {
            @media screen and (max-width: $media-1024) {
                width: 25%;
            }
        }
    }
}

.hide-mobile {
    display: block;
}

.show-mobile {
    display: none;
}
.refill-dashboard {
    @media (min-width: $media-750) and (max-width: $media-1024) {
        #primary {
            width: 75%;
            padding-left: 0;
            border: 0;
        }
    
        #secondary {
            width: 23%;
        }
    
        .primary-focus {
            #secondary {
                width: 23%;
            }
        }
    }
}

@media (max-width: $media-750) {
    .refill-dashboard {
        #main {
            padding-top: scalesize(1.5rem);
        }
    }

    #my_subscriptions {
        overflow-x: scroll;

        .subscription-info {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-align: center;
            align-items: center;
        }

        .show-hide-orders {
            width: 100%;
            margin-right: 0;
            text-align: center;

            .down-arrow {
                display: inline-block;
                width: scalesize(1rem);
                margin: scalesize(1rem);
                margin-top: scalesize(0.5rem);
            }
        }

        .subscriptionHeader {
            text-align: center;
        }
    }

    .primary-content {
        &:not(.row) {
            width: 100%;
        }

        .form-row {
            &:not(.label-inline) {
                > .field-wrapper {
                    width: 100%;
                }
            }
        }

        .form-row.form-row-button {
            text-align: center;
        }
    }

    .primary-focus {
        .primary-content {
            width: 100%;
        }
    }

    #secondary {
        width: 100%;
    }

    .group-change-refill {
        margin: 0 0 scalesize(1rem) 0;
    }

    .payment-section__card--item {
        width: 100%;
    }

    #subscriptionview {
        #actions {
            span {
                &:last-of-type {
                    float: left;
                }
            }
        }

        .order-section {
            p.title-little {
                display: inline;
            }
        }
    }

    .subscriptionOrders {
        .orders {
            margin: scalesize(1rem) scalesize(1rem) scalesize(1rem) 0;
        }
    }
}

@media (max-width: $media-500) {
    .orders-buttons {
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media (max-width: $media-450) {
    .hide-mobile {
        display: none;
    }

    .show-mobile {
        display: block;
    }
}

.refill-li-message {
    font-weight: bold;
    font-size: scalesize(1.4rem);
    line-height: 1.2em;
}

.SOR-modal {
    .ui-dialog-content,
    .ui-dialog-buttonpane {
        margin: 0;
        width: 100% !important;
        box-sizing: border-box;
    }

    .ui-dialog-content {
        padding: 40px 40px 0 40px;

        p {
            padding: 16px 0;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
        }
    }

    .ui-dialog-buttonpane {
        padding: 0 40px 40px 40px;

        .ui-button-text {
            background-color: #fff;
            border-radius: 0;
            color: #000;
            cursor: pointer;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 18px;
            padding: 6px 32px;
            letter-spacing: 1px;
            text-align: center;
            transition: all 0.3s ease;
            position: relative;
            display: inline-block;
            overflow: visible;
        }

        .ui-button {
            margin: 0;
            
            &:not(:last-child) {
                margin-right: 16px;
            }

            &:nth-child(2) span {
                background-color: #b52532;
                color: #fff;
            }
        }
    }
}

.smart-order-refill-modal {
    top: 60px !important;
    margin-bottom: 20px;

    .order-date {
        font-family: $head-font;
        color: #000;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        text-transform: uppercase;
    }

    .order-section {
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0;
            font-family: $head-font;
            font-size: 24px;
            font-weight: 500;
            text-transform: uppercase;

            span {
                font-family: $body-font;
                text-transform: capitalize;
            }
        }
    }
}
